import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';

const BannerContainer = styled.div`
  position: relative;
  border-bottom: 5px solid #f7941e;
`;

const Tagline = styled.span`
  position: absolute;
  top: 60%;
  left: 25%;
  color: #ffffff;
  background-color: #f7941e;
  white-space: nowrap;
`;

const StyledImg = styled(Img)`
  width: 25%;
`;

const Banner = props => (

  <StaticQuery
    query={graphql`
      query ImageQuery {
        bannerImage: file(relativePath: { eq: "dog.png" }) {
          childImageSharp {
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <BannerContainer>
        <StyledImg
          fluid={data.bannerImage.childImageSharp.fluid}
        />
        <Tagline>HUMOR IS SERIOUS BUSINESS</Tagline>
      </BannerContainer>
    )}
  />
)

export default Banner

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';

import Header from './header';
import Banner from './banner';
import Contact from './contact';

const Layout = props => (

  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}

    render={data => (
        <>
          <Helmet>
            <title>{data.site.siteMetadata.title}</title>
            <meta description='Serious Business' />
            <meta keywords='Serious Business, humour, comedy' />
            <meta viewport='width=device-width, initial-scale=1' />
            <meta charset='utf-8' />
            <meta x-ua-compatible='ie=edge' />
            <link
              rel="stylesheet"
              href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
              integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
              crossorigin="anonymous"
            />
            <link
              rel="stylesheet"
              href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
              crossorigin="anonymous"
            />
            <link rel="stylesheet" href="./styles/global.css" />
          </Helmet>
          <Header siteTitle={data.site.siteMetadata.title} />
          <Container>
            <Row>
              <Col sm={12} md={{ span: 10, offset: 1 }}>
                <Banner  />
                {props.children}
                <Contact />
              </Col>
            </Row>
          </Container>
        </>
      )}
    />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

import React from 'react'
import styled from 'styled-components';
import { Navbar, Nav, Image } from 'react-bootstrap';

class Header extends React.Component {

  constructor(props) {
     super(props);
     this.state = { showNav: false };
     this.handleScroll = this.handleScroll.bind(this);
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  };

  handleScroll() {
    if (window.scrollY > 100) {
      this.setState({ showNav: true });
    } else {
      this.setState({ showNav: false });
    }
  };

  render() {

    const StyledNavbar = styled(Navbar)`
      display: hidden;
    `;

    const StyledNavbarBrand = styled(Navbar.Brand)`
      position: relative;
      background: url(/images/logo.png) no-repeat;
      width: 150px;
      height: 100px !important;
      left: 15px;
      background-size: contain;
    `;

    const StyledImg = styled(Image)`
      height: 32px;
      width: 32px;
    `;

    const StyledAnchor = styled.a`
      width: 100%;
      height: 100%;
      display: block;
    `;

    return this.state.showNav && (
        <StyledNavbar fixed="top" bg="light" expand="md" id="serious-navbar">
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <StyledNavbarBrand>
                <StyledAnchor href="/">&nbsp;</StyledAnchor>
              </StyledNavbarBrand>

             <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="ml-auto">
                <Nav.Item>
                  <Nav.Link href="/#">About Us</Nav.Link>
                </Nav.Item>
                <Nav.Item >
                  <Nav.Link href="/#services">Services</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/#contact">Contact</Nav.Link>
                </Nav.Item>
                <Nav.Item className="d-none d-md-block">
                  <a href="https://www.linkedin.com/in/sacbha/" target="_blank">
                    <StyledImg src="/images/Linkedin.png"/>
                  </a>
                </Nav.Item>
                <Nav.Item className="d-none d-md-block">
                  <a href="https://www.instagram.com/sachitbhatia/" target="_blank">
                    <StyledImg src="/images/Instagram.png"/>
                  </a>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
        </StyledNavbar>
    )
  };

}

export default Header

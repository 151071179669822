import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col, Image } from 'react-bootstrap';

const ContactContainer = styled.div`
  position: relative;
  border-top: 5px solid #f7941e;
  margin: 15px 0 30px 0;
`;

const Heading = styled.div`
  margin: 30px 0 30px 0;
  text-align: center;
`;

const Title = styled.p`
  font-weight: bold;
  font-size: larger;
  color: #000000;
`;

const StyledCol = styled(Col)`
  text-align: center;
`;

const Content = styled.div`
  display: inline-block;
  text-align: justify;
`;

const ContentLeft = styled.div`
  text-align: left;
`;

const ContentRight = styled.div`
  text-align: right;
`;

const StyledImg = styled(Image)`
  height: 32px;
  width: 32px;
`;

const Link = styled.a`
  text-align: center;
`;

const Contact = () => (
  <ContactContainer>
    <Container fluid="true" id="contact">
      <Row>
        <StyledCol xs={12}>
          <Heading>
            <Title>Contact Us</Title>
          </Heading>
          <Content>
            <p>We are passionate about Marketing and Humor and all things in-between.
              We love to help everyone with interesting funny content and give free
              advise on other non-funny matters we don't know anything about.
            </p>
            <p>&nbsp;</p>
            <p>Moral of the story - if you have a problem that can be solved with humor,
              we most likely would have a solution or two (or even more if the money is good).
            </p>
            <p>&nbsp;</p>
          </Content>
        </StyledCol>
      </Row>
      <Row>
        <StyledCol xs={9}>
          <ContentLeft>
            <p>Let's connect.<br/>
              <p>&nbsp;</p>
              <span className="fa fa-envelope" /> &nbsp;
              <Link className="text-center" href="mailto:sachit@seriousbusiness.in">
                <b>sachit@seriousbusiness.in</b>
              </Link>
            </p>
          </ContentLeft>
        </StyledCol>
        <StyledCol xs={3}>
          <ContentRight>
            <p>&nbsp;</p>
            <a href="https://www.linkedin.com/in/sacbha/" target="_blank">
              <StyledImg src="/images/Linkedin.png"/>
            </a>
            &nbsp;
            <a href="https://www.instagram.com/sachitbhatia/" target="_blank">
              <StyledImg src="/images/Instagram.png"/>
            </a>
          </ContentRight>
        </StyledCol>
      </Row>
    </Container>
  </ContactContainer>
)

export default Contact
